<template>
  <b-container fluid class="p-0 pt-2">
    <!-- Extracurricular Add -->
    <b-modal
      v-model="showModeldocAdd"
      scrollable
      :title="cvAddModalHeader"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
      :noCloseOnBackdrop="true"
    >
      <YjsDocsAttach :propUserDetails="propGideUser" @emitClosedocAddModal="closedocAddModal" :propOpenedInEditMode="propOpenedInEditMode" :propUspExcId="propUspExcId" :propSchoolDetails="propSchoolDetails" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="yjsDocsAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closedocAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Extracurricular Add -->

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
    <template v-if="addEducationShow">
      <Education :propEducationForm="educationForm" :propEducationAddShow='addEducationShow' @emitCloseEducationModal="closeEducationModal" @emitUserProfileEducation="addEducation"></Education>
    </template>
    <template>
      <ProgramTest v-if="showProgramTestModal" :propProgramDetail="programEditObj" :propOpenedInModal="showProgramTestModal" @emitCloseProgramModal="closeProgramModal">
      </ProgramTest>
    </template>
    <b-row class="m-0 Documents admission_personal_info">
      <b-col md="7" sm="6" lg="6">
      <!--If this is not edit or add page only then show  -->
      <b-col md="12" class="review_form_spacing">
      <div class="mt-3 table_scroll">
        <h4>Personal Information</h4>
        <hr class="w-100 theme_color"/>
        <PersonalInfo v-if="studentData" :propUserId="studentData.user_id" :propUserData="studentData" @emitRefreshUserDetails="emitRefreshUserDetails"/>
      </div>
      </b-col>

      <b-col md="12" class="review_form_spacing">
      <h4>Education
        <span class="educationAddIcon" @click="showAddEducation()"><i class="fa-solid fa-circle-plus pull-right"></i></span>
      </h4>
      <hr class="w-100 theme_color"/>
      <div class="mt-3 iq-card p-3">
        <Education :propEducationList="user_profile.education" :propFromResumeBuilder="true" @emitUserProfileEducation="addEducation" @emitGetOpenEducationFun="emitGetOpenEducationFun">
        </Education>
      </div>
      <div class="mt-3">
        <ResumeLinks @emitRebuildUserSocialLinks="emitRebuildUserSocialLinks"/>
      </div>
      </b-col>

      <b-col md="12" class="review_form_spacing">
        <h4>Programs & Tests
          <span class="educationAddIcon pull-right" @click="showProgramModal()"><i class="fa-solid fa-circle-plus"></i></span>
        </h4>
        <hr class="w-100 theme_color"/>
        <div class="mt-3 iq-card p-3 pt-2 " v-for="(item, index) in userProgramTest" :key="index+'program'" >
          <ProgramTest :propProgramDetail="item" :propOpenedInModal="false"></ProgramTest>
        </div>
      </b-col>
      <b-col md="12" class="review_form_spacing">
        <h4>Extra Curriculars
          <span class="educationAddIcon pull-right" @click="openYjsDocsAttach()"><i class="fa-solid fa-circle-plus"></i></span>
        </h4>
        <hr class="w-100 theme_color"/>
        <div class="educationCard iq-card p-3">
          <ResumeExtraCurricular @emitNewExcAdded="emitNewExcAdded" @emitRefreshUserProfile="emitRefreshUserProfile" :propExcObjList="extraCurricularListObj" :propEducationList="user_profile.education" />
        </div>
      </b-col>
      </b-col>
      <b-col md="5" sm="6" lg="6">
        <div class="border p-2 resume_sample">
          <Resume :propResumeDetails="propResumeDetails"/>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Education from "../../../components/UserProfile/Education.vue"
import PersonalInfo from "../../../components/UserProfile/PersonalInfo.vue"
import ProgramTest from "../../../components/UserProfile/ProgramTest.vue"
import { socialvue } from "../../../config/pluginInit"
import { Countries } from "../../../FackApi/api/country"
import { User } from "../../../FackApi/api/user"
import YjsDocsAttach from "../YjsDocs/YjsDocsAttach.vue"
import Resume from "./Resume.vue"
import ResumeExtraCurricular from "./ResumeExtraCurricular.vue"
import ResumeLinks from "./ResumeLinks.vue"

export default {
  name: "ResumeBuild",
  components: {
    YjsDocsAttach,
    Education,
    PersonalInfo,
    ProgramTest,
    Resume,
    ResumeLinks,
    ResumeExtraCurricular
  },
  methods: {
    /**
     * showProgramModal
     */
    showProgramModal () {
      this.showProgramTestModal = true
      this.programEditObj = {}
    },
    /**
     * showAddEducation
     */
    showAddEducation () {
      this.addEducationShow = true
      this.educationForm = {
        subjects: [],
        videoLinks: [],
        attachments: []
      }
    },
    /**
     * openYjsDocsAttach
     */
    openYjsDocsAttach () {
      this.propGideUser = this.userData
      this.showModeldocAdd = true
    },
    /**
     * viewUserProfile
     */
    async viewUserProfile () {
      const userProfile = await User.userProfileView(this, this.userData.user_id)
      if (userProfile.resp_status) {
        this.extraCurricularListObj = userProfile.resp_data.data.extraCurricularListObj

        if (userProfile.resp_data.data.user_profile) {
          if (typeof userProfile.user_profile === "string") {
            this.user_profile = JSON.parse(userProfile.resp_data.data.user_profile)
          }
          else {
            this.user_profile = userProfile.resp_data.data.user_profile
          }

          let userAddress = null
          try {
            userAddress = JSON.parse(userProfile.resp_data.data.user_address)
          }
          catch (err) {
            // do nothing
          }
          if (userAddress) {
            this.resumeDtails = { ...this.resumeDtails, user_address: `${userAddress.user_city},${this.userCountry ? this.userCountry : "India"}` }
          }

          let educationDetails = this.user_profile.education
          // we are displaying the educational details of class 12 in the resume
          educationDetails = educationDetails.find(edu => edu.education.class_name == 12)

          if (!educationDetails) {
            // Prompt the user to fill in the education details of class 12
            this.toastMsg = "Please fill in the education details of class 12"
            this.toastVariant = "warning"
            this.showToast = true
            return
          }

          if (
            educationDetails.start_date &&
            educationDetails.end_date &&
            educationDetails.examination_body &&
            educationDetails.percentage
          ) {
            this.resumeDtails.user_education = [{
              school_name: educationDetails.institute_name,
              session_start_date: educationDetails.start_date.split("-")[0],
              session_end_date: educationDetails.end_date.split("-")[0],
              school_board: educationDetails.examination_body,
              agregate_percentage: educationDetails.percentage
              // current_activity: "Currently pursuing Advanced Subsidiary and Advanced Level"
            }]
          }
        }

        if (userProfile.resp_data.data.user_program_test) {
          this.userProgramTest = JSON.parse(userProfile.resp_data.data.user_program_test)
        }

        let userProgTest = this.userProgramTest
        let userProgTestArr = []

        if (userProgTest && Object.keys(userProgTest).length > 0) {
          Object.keys(userProgTest).forEach(test => {
            const testObj = this.buildProgramTestObj(test, userProgTest[test])
            if (testObj) {
              userProgTestArr.push(testObj)
            }
          })
          this.resumeDtails.user_program_test = userProgTestArr
        }
      }
      // Building user's social links
      let userSocialLinks = null
      userSocialLinks = userProfile.user_social_link && userProfile.user_social_link.social_links
      if (userSocialLinks) {
        userSocialLinks = JSON.parse(userSocialLinks)
        let linksToInclude = ["linkedin"]
        userSocialLinks = Object.keys(userSocialLinks).filter(lnk => linksToInclude.includes(lnk)).map(platform => userSocialLinks[platform])
        this.user_social_link = userSocialLinks
      }
    },
    /*
     * addEducation
     */
    async addEducation (payload) {
      if (!this.user_profile.education) {
        this.user_profile.education = []
      }

      const educationIndex = this.user_profile.education.findIndex(edu => edu.id === payload.newEducation.id)
      if (educationIndex >= 0) {
        this.user_profile.education[educationIndex] = payload.newEducation
      }
      else {
        this.user_profile.education.unshift(payload.newEducation)
      }

      this.extraCurricularListObj[payload.newEducation.id] = payload.extraCurricularList
      this.viewUserProfile()
      this.addEducationShow = false

      await this.viewUserProfile()
      this.userResumeBuild()
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }
        countryDistinctListResp = countryDistinctListResp.resp_data.data
        this.userCountry = countryDistinctListResp.find(e => e.id == this.userData.user_country).country_name
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * closeEducationModal
     */
    async closeEducationModal () {
      this.addEducationShow = false

      await this.viewUserProfile()
      this.userResumeBuild()
    },
    /**
     * emitNewExcAdded
     */
    emitNewExcAdded (eduId) {
      this.openEduFunFromChild(eduId)
    },
    /**
     * emitRefreshUserProfile
     */
    async emitRefreshUserProfile (section, prevSection, exc, isAdded) {
      await this.viewUserProfile()
      // change the propResumeDetails locally first and when the user refreshes the browser tab, the resume will be build on the server
      if (isAdded == true) {
        if ((prevSection && prevSection != "NULL") && prevSection != section) {
          // Remove it from the previous section
          this.modifyResumeDelete(prevSection, exc)
        }
        // Adding it to the new section
        this.modifyResumeAdd(section, exc)
      }
      else if (isAdded == false) {
        this.modifyResumeDelete(section, exc)
      }
      else {
        // A.I has updated the resume details so rebuild the resume
        this.userResumeBuild()
      }
    },
    /**
     * emitRebuildUserSocialLinks
     */
    async emitRebuildUserSocialLinks () {
      await this.viewUserProfile()
      this.userResumeBuild()
    },
    /**
     * emitGetOpenEducationFun
     */
    emitGetOpenEducationFun (openEducationFunction) {
      this.openEduFunFromChild = openEducationFunction
    },
    /**
     * closeProgramModal
     */
    closeProgramModal (event) {
      if (event) {
        this.userProgramTest = JSON.parse(event.resp_data.user_program_test)
      }

      this.viewUserProfile()
      this.userResumeBuild()

      this.showProgramTestModal = false
    },
    /**
     * emitRefreshUserDetails
     */
    async emitRefreshUserDetails () {
      console.log("Building personal data")
      await this.viewUserProfile()
      this.userResumeBuild()
    },
    /**
     * buildProgramTestObj
     */
    buildProgramTestObj (test, testDetails) {
      const testObj = {}
      switch (test) {
        case "SAT":
          testObj.examination_name = test + "(Scholastic Assessment Test)"
          testObj.marks_breakdown = [
            {
              subject: "Critical Reading",
              marks: testDetails.critical_reading
            },
            {
              subject: "Math",
              marks: testDetails.math_score
            },
            {
              subject: "Writing",
              marks: testDetails.writing_score
            },
            {
              subject: "Event Based Reading & Writing",
              marks: testDetails.evidence_based_reading_writing_score
            }
          ]
          testObj.total_marks = testObj.marks_breakdown.reduce((acc, marksObj) => {
            return acc + Number(marksObj.marks)
          }, 0)
          return testObj
        case "ACT":
          testObj.examination_name = test + "(American College Testing)"
          testObj.marks_breakdown = [
            {
              subject: "English",
              marks: testDetails.english_score
            },
            {
              subject: "Math",
              marks: testDetails.math_score
            },
            {
              subject: "Reading",
              marks: testDetails.reading_score
            },
            {
              subject: "Science",
              marks: testDetails.science_score
            }
          ]
          testObj.total_marks = testDetails.composite_score
          return testObj
        case "IELTS":
          testObj.examination_name = test + "(International English Language Testing System)"
          testObj.marks_breakdown = [
            {
              subject: "Listening",
              marks: testDetails.listening_score
            },
            {
              subject: "Writing",
              marks: testDetails.writing_score
            },
            {
              subject: "Reading",
              marks: testDetails.reading_score
            }
          ]
          testObj.total_marks = testDetails.overscore_score
          return testObj
        case "TOEFL":
          testObj.examination_name = test + "(Test of English as a Foreign Language)"
          testObj.marks_breakdown = [
            {
              subject: "Listening",
              marks: testDetails.listening_score
            },
            {
              subject: "Writing",
              marks: testDetails.writing_score
            },
            {
              subject: "Reading",
              marks: testDetails.reading_score
            }
          ]
          testObj.total_marks = testDetails.overscore_score
          return testObj
      }
    },
    /**
     * userResumeBuild
     */
    async userResumeBuild () {
      // optimal height of the content:1585px
      try {
        this.resumeDtails = {
          ...this.resumeDtails,
          user_name: this.userData.user_name,
          user_mobile: this.userData.user_mobile,
          user_email: this.userData.user_email,
          user_social_links: this.user_social_link,
          user_experience: [],
          user_awards: [],
          user_courses: []
        }
        // separating the extra curriculars into various sections of the resume

        let userExperience = []
        let userAwards = []
        let userCourses = []
        if (this.extraCurricularListObj && Object.keys(this.extraCurricularListObj).length > 0) {
          Object.keys(this.extraCurricularListObj).forEach(eduId => {
            this.extraCurricularListObj[eduId].forEach(exc => {
              switch (exc.resume_section) {
                case "EXP":
                  userExperience.push({
                    exc_id: exc.exc_id,
                    role: exc.exc_title,
                    org: exc.exc_org,
                    achievements: [exc.exc_desc]
                  })
                  break

                case "A&H":
                  userAwards.push({
                    exc_id: exc.exc_id,
                    title: exc.exc_title,
                    org: exc.exc_org,
                    details: [exc.exc_desc]
                  })
                  break

                case "C&W":
                  userCourses.push({
                    exc_id: exc.exc_id,
                    title: exc.exc_title,
                    org: exc.exc_org,
                    details: [exc.exc_desc]
                  })
                  break
              }
            })
          })
          this.resumeDtails.user_experience = userExperience
          this.resumeDtails.user_awards = userAwards
          this.resumeDtails.user_courses = userCourses
        }
        // Comment the line below to see the sample resume template
        this.propResumeDetails = this.resumeDtails
      }
      catch (err) {
        console.error("Exception in userResumeBuild and error: ", err)
      }
    },
    /**
     * modifyResumeAdd
     * Adds the extra curricular to the resume in the UI
     */
    modifyResumeAdd (section, exc) {
      switch (section) {
        case "EXP":
          const exp = {
            exc_id: exc.exc_id,
            role: exc.exc_title,
            org: exc.exc_org,
            achievements: [exc.exc_desc]
          }
          this.propResumeDetails.user_experience.push(exp)
          break
        case "A&H":
          const ah = {
            exc_id: exc.exc_id,
            title: exc.exc_title,
            org: exc.exc_org,
            details: [exc.exc_desc]
          }
          this.propResumeDetails.user_awards.push(ah)
          break
        case "C&W":
          const cw = {
            exc_id: exc.exc_id,
            title: exc.exc_title,
            org: exc.exc_org,
            details: [exc.exc_desc]
          }
          this.propResumeDetails.user_courses.push(cw)
          break
      }
    },
    /**
     * modifyResumeDelete
     * Removes the extra curricular to the resume in the UI
     */
    modifyResumeDelete (section, exc) {
      const resumeSecAliasMap = {
        "EXP": "user_experience",
        "A&H": "user_awards",
        "C&W": "user_courses"
      }
      const resumeSecArr = this.propResumeDetails[resumeSecAliasMap[section]]
      // remove the exc from the array
      const newResumeSecArr = resumeSecArr.filter(e => e.exc_id != exc.exc_id)
      this.propResumeDetails = { ...this.propResumeDetails, [resumeSecAliasMap[section]]: newResumeSecArr }
    },
    /**
     * closedocAddModal
     */
    closedocAddModal (docsAddData) {
      try {
        if (!this.extraCurricularListObj[docsAddData.usp_edu_id]) {
          this.extraCurricularListObj[docsAddData.usp_edu_id] = []
        }
        this.extraCurricularListObj[docsAddData.usp_edu_id].push(docsAddData)
        this.showModeldocAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closedocsAddModal() and Exception:", err.message)
      }
    }
  },
  data () {
    return {
      vmAdmissionForm: {},
      addEducationShow: false,
      educationForm: null,
      showProgramTestModal: false,
      programEditObj: null,
      user_profile: {},
      user_social_link: {},
      studentData: null,
      propUserResumeDetails: null,
      userProgramTest: null,
      editMode: false,
      extraCurricularListObj: null,
      propResumeDetails: null,
      userProfileData: null,
      resumeDtails: {},
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Resume Extracurriculars",
      videoLinks: [],
      userCountry: null,
      openEduFunFromChild: null,
      propGideUser: null,
      propOpenedInEditMode: false,
      propUspExcId: null,
      propSchoolDetails: {},
      cvAddModalHeader: "Add Extracurricular",
      showModeldocAdd: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    this.studentData = this.userData
    await this.countryDistinctList()
    await this.viewUserProfile()
    await this.userResumeBuild()
  }
}
</script>

<style scoped lang="scss">
  .educationAddIcon {
    float: right;
    color: var(--iq-primary);
    cursor: pointer;
  }
.Documents{
  .document_hr_color{
    border-color: var(--iq-primary) !important;
  }
}
.resume_sample {
  min-width: 40%;
  max-height: 80%;
  position: fixed;
  overflow: auto;
}
@media screen and (max-width: 576px) {
  .resume_sample {
    max-height: unset;
    position: unset;

  }
}
</style>
